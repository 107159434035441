.loader {
  max-width: 80px;
  width: 100%;
  height: auto;
  stroke-linecap: round;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9;
    &-relative {
      position: relative;
      transform: unset;
      top: unset;
      left: unset;
    }
  }
}

.loader circle {
  fill: none;
  stroke-width: 3.5;
  animation-name: preloader;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transform-origin: 170px 170px;
  will-change: transform;
  &:nth-of-type(1) {
    stroke-dasharray: 550;
    animation-delay: -0.15s;
  }
  &:nth-of-type(2) {
    stroke-dasharray: 500;
    animation-delay: -0.3s;
  }
  &:nth-of-type(3) {
    stroke-dasharray: 450;
    animation-delay: -0.45s;
  }
  &:nth-of-type(4) {
    stroke-dasharray: 300;
    animation-delay: -0.6s;
  }
  &:nth-of-type(5) {
    animation-delay: -0.75s;
    stroke-dasharray: 200;
  }
}

.u-loading::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
